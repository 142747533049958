import * as React from 'react'
import PropTypes from 'prop-types'
import { StaticImage } from 'gatsby-plugin-image'
import './styles.scss'

const Footer = () => {
    const currentYear = new Date().getFullYear()

    return (
        <footer className="c-section c-footer">
            <div className="container">
                <StaticImage
                    src="../../images/ctc-logo-white.png"
                    width={180}
                    alt="Compare the Quote logo"
                    className="c-footer__logo"
                />

                <p>Copyright &copy; Protect Line 2010-{currentYear}</p>

                <p>
                    Life insurance plans have no cash in value at any time and
                    will cease at the end of the term. If premiums are not
                    maintained, then cover will lapse.
                </p>

                <p>
                    We search from our panel of insurers to find you a
                    competitive life insurance quote. Quotes are illustrative
                    and based on perfect health and lifestyle.
                </p>

                <p>
                    The actual rate will depend upon your needs, circumstances
                    and preferences. Premiums will be based on the sum you are
                    looking to be covered for, the length of the policy, and
                    whether or not you need any add-ons such as critical illness
                    cover.
                </p>

                <p>
                    Most individual policies are subject to a medical
                    underwriting process where questions regarding health,
                    lifestyle, occupation and medical history may be asked.
                </p>

                <p>
                    CompareTheQuote.co.uk is a trading style of Protect Line
                    Ltd. Protect Line Ltd is registered in England and Wales
                    registered no. 7059779. Registered Address: Protect Line
                    Ltd, Bourne Gate, 25 Bourne Valley Road, Poole, BH12 1DY
                    Contact telephone number: 01202 977912
                </p>

                <p>
                    Protect Line Ltd is an insurance broker and not an insurer.
                    The service is ‘fee-free’ in that there are no upfront or
                    separate charges for consultations, quotes and discussions
                    with a Protect Line specialist. The cost of setting up the
                    policy will be included in the premiums you pay. Protect
                    Line will not bill you separately for any guidance you
                    receive from one of our specialists.
                </p>

                <p>
                    The guidance provided within this website is subject to the
                    UK regulatory regime and is therefore primarily targeted at
                    consumers based in the UK.
                </p>

                <p>
                    Protect Line Ltd is authorised and regulated by the
                    Financial Conduct Authority. Protect Line Ltd is entered on
                    the Financial Services Register (
                    <a href="https://www.fca.org.uk/firms/financial-services-register">
                        www.fca.org.uk/register
                    </a>
                    ) under reference 942467.
                </p>
            </div>
        </footer>
    )
}

Footer.propTypes = {
    /**
     * Global 'Site Options' data
     */
    options: PropTypes.object.isRequired
}

Footer.defaultProps = {
    options: {
        companyDetails: {
            address: '123 Fake Street',
            telephoneNumber: '0000 000 0000'
        },
        footer: {
            column1: {
                title: 'Column Title',
                linksRepeater: [
                    {
                        link: {
                            target: '',
                            title: 'Link 1',
                            url: '#'
                        }
                    },
                    {
                        link: {
                            target: '',
                            title: 'Link 2',
                            url: '#'
                        }
                    }
                ]
            },
            column2: {
                title: 'Column Title',
                linksRepeater: [
                    {
                        link: {
                            target: '',
                            title: 'Link 1',
                            url: '#'
                        }
                    },
                    {
                        link: {
                            target: '',
                            title: 'Link 2',
                            url: '#'
                        }
                    }
                ]
            },
            column3: {
                title: 'Column Title',
                content: '<p>Column free content</p>'
            },
            copyright: '<p>&copy; Company Name</p>'
        }
    },
    utm: ''
}

export default Footer
